import React from "react"
import { Text } from "theme-ui"

import Part from "../../../components/adventures/short-stories/Part"
import Section from "../../../components/adventures/Section"
import Block from "../../../components/adventures/Block"

export default function Part06() {
  return (
    <Part title="Conclusion" time="5–20 minutes">
      <Section type="Context">
        <Block type="Secret Context" title="Outcomes">
          <Text as="h3" variant="title03" sx={{ mt: 3 }}>Defeat</Text>
          <Text as="p" variant="body">If The Party is defeated, the townspeople in the audience will come collect the treasure and put it in the toy box on the stage. If you are running a lighter version of the adventure where the players cannot die, The Trickster will snap their fingers and teleport the players back to Treasure Town, where they will have the option of running the adventure again. If you run the adventure again, you can use the optional content for the adventure to present them with new challenges. We also encourage you to create your own encounters and segments of the adventure if you would like to repeat it.</Text>
          <Text as="h3" variant="title03" sx={{ mt: 3 }}>Success</Text>
          <Text as="p" variant="body">If The Party successfully defeats the toy, The Trickster will congratulate them and have the audience clap and cheer. Honoring the agreement, they will open a portal and allow the adventurers to return to their world with their rewards. If you decide to end the adventure here, have the players describe how they celebrate their victory and what they do with their treasure after returning home.</Text>
          <Text as="p" variant="body">If you plan to continue the adventure, you can create a new storyline in their home world. If you want to throw them into the unknown, you can have The Trickster’s portal bring them to a different world than the one they arrived from.</Text>
          <Text as="h3" variant="title03" sx={{ mt: 3 }}>Boss Battle</Text>
          <Text as="p" variant="body">The Party may choose to fight The Trickster instead of ending the adventure after defeating the toy. If Lia is with The Party, they will confront The Trickster and attack them, with or without The Party’s help.</Text>
          <Text as="p" variant="body">If The Party defeats The Trickster, they will discover a magic amulet that will allow them to open a portal to return home. If Lia is present, they will use the amulet to destroy the Shadow Plane and return Mischief Mountain to the real world. As a reward, Lia will give the players back the treasure they offered in good faith.</Text>
        </Block>
      </Section>
    </Part>
  )
}
