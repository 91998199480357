import React from "react"
import { Grid, Text, Flex, Box, Image } from "theme-ui"

import wavesImage from '../../images/waves.png'
import caretsImage from '../../images/carets.png'

export default function Section({ type, children, cite, title, name, pronouns, description, hp, atk, specialFeatures, specialAbilities, inventory, image }) {
  return (
    <Grid
      variant="base"
      sx={{
        background: 
          theme => `${
            type === "Dialog" ? theme.colors.primary10 : 
            type === "Character" ? theme.colors.grayXLight : 
            // type === "Encounter" ? `repeating-linear-gradient(45deg, ${theme.colors.background}, white 15px, ${theme.colors.grayMedium} 15px, ${theme.colors.grayMedium} 16px)` : 
            type === "Encounter" ? `url('${caretsImage}') center top repeat` : 
            type === "Setup" ? `url('${wavesImage}') center top repeat` : 
            type === "Context" ? `linear-gradient(90deg, ${theme.colors.background} 12px, transparent 1%) right top, linear-gradient(${theme.colors.background} 12px, transparent 1%) right top, ${theme.colors.grayMedium}` :
            theme.colors.grayXLight
          }`,
        backgroundSize: 
          type === "Context" ? "14px 14px" : 
          type === "Encounter" ? "50px 44px" : 
          type === "Setup" ? "56px 56px" : 
          "auto" ,
        gridGap: "0px",
        py: [5, 6],
        my: [5, 6]
      }}
    >
      {(type === "Context" || type === "Encounter" || type === "Setup") && children}
      
      {type === "Dialog" &&
        <Flex>
          <Text variant="display" sx={{ display: "inline", flexShrink: 0, mt: -2, mr: 4 }}>“</Text>
          <Box>
            <Text
              variant="bodyLarge"
              sx={{
                "> div": {
                  mb: 1
                }
              }}
            >
              {children}
            </Text>
            {cite && <Text as="p" variant="body" sx={{ mt: 2 }}>— {cite}</Text>}
          </Box>
        </Flex>
      }

      {type === "Character" &&
        <Grid columns={[1, 2]}>
          {image && <Image src={image} alt={name} sx={{ maxWidth: 400, my: [0, -8], justifySelf: "center", zIndex: 2 }} />}
          
          <Box>
            <Text as="h3" variant="title02">{name}</Text>
            <Text as="p" variant="label" sx={{ mt: -2 }}>{pronouns}</Text>
            <Text
              variant="body"
              sx={{
                "> div": {
                  mb: 1
                }
              }}
            >
              {description}
            </Text>

            <Flex sx={{ justifyContent: "space-between", alignItems: "center", my: 2 }}>
              <Text as="p" variant="title03">HP {hp}</Text>
              <Flex sx={{ flexGrow: 1, height: "2px", bg: "text", mx: 2 }} />
              <Text as="p" variant="title03">ATK {atk}</Text>
            </Flex>
            
            {specialFeatures && <Text as="p" variant="body" sx={{ m: 0 }}>Special features: </Text>}
            {specialFeatures && specialFeatures.map(feature => <Text key={feature} as="span" variant="label" sx={{ bg: "grayDark", color: "background", borderRadius: 2, px: "4px", mr: 2 }}>{feature}</Text>)}
            
            {specialAbilities && <Text as="p" variant="body" sx={{ m: 0 }}>Special abilities: </Text>}
            {specialAbilities && specialAbilities.map(ability => <Text key={ability} as="span" variant="label" sx={{ bg: "grayDark", color: "background", borderRadius: 2, px: "4px", mr: 2 }}>{ability}</Text>)}
            
            {inventory && <Text as="p" variant="body" sx={{ m: 0 }}>Inventory: </Text>}
            {inventory && inventory.map(item => <Text key={item} as="span" variant="label" sx={{ bg: "grayDark", color: "background", borderRadius: 2, px: "4px", mr: 2 }}>{item}</Text>)}
          </Box>
        </Grid>
      }
    </Grid>
  )
}
