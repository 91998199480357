import React from "react"
import { Grid, Box, Text } from "theme-ui"

import { 
  DescriptiveTextIcon,
  MagicKeyIcon,
  MaskIcon,
  CharactersIcon,
  DialogIcon
} from '../Icon'

export default function Block({ type, title, name, cite, children, hp, atk, specialFeatures, specialAbilities, inventory, other }) {
  return (
    <Grid
      columns={[1, "1fr 3fr 1fr", "1fr 4fr 1fr"]}
      sx={{
        bg: "white",
        p: [4, 6],
        "&:not(:nth-of-type(1))": {
          mt: [-4, -6]
        }
      }}
    >
      <Box>
        <Text variant="label" sx={{ mb: 0 }}>{type}</Text>
        {(type === "Exposition" || type === "Dialog") && <Text variant="label" sx={{ color: "grayDark" }}>Read Aloud</Text>}
        <Box sx={{ width: 60, mt: 2, justifySelf: "end" }}>
          {type === "Exposition" && <DescriptiveTextIcon /> }
          {type === "Open Context" && <MagicKeyIcon /> }
          {type === "Secret Context" && <MaskIcon /> }
          {type === "Character" && <CharactersIcon /> }
          {type === "Dialog" && <DialogIcon /> }
        </Box>
      </Box>
      
      <Box>
        {title && <Text as="h3" variant="title02" sx={{ mt: -1, mb: 1 }}>{title}</Text>}
        {name && <Text as="h3" variant="title02" sx={{ mt: -1, mb: 1 }}>{name}</Text>}
        <Text
          variant={(type === "Exposition" || type === "Dialog") ? "bodyLarge" : "body"}
          sx={{
            "> div": {
              mb: 1
            }
          }}
        >
          {children}
        </Text>

        {hp && <Text variant="label">HP: {hp}</Text>}
        {atk && <Text variant="label">ATK: {atk}</Text>}
        
        {specialFeatures && <Text as="p" variant="body" sx={{ m: 0 }}>Special features: </Text>}
        {specialFeatures && specialFeatures.map(feature => <Text key={feature} as="span" variant="label" sx={{ bg: "grayDark", color: "background", borderRadius: 2, px: "4px", mr: 2 }}>{feature}</Text>)}
        
        {specialAbilities && <Text as="p" variant="body" sx={{ m: 0 }}>Special abilities: </Text>}
        {specialAbilities && specialAbilities.map(ability => <Text key={ability} as="span" variant="label" sx={{ bg: "grayDark", color: "background", borderRadius: 2, px: "4px", mr: 2 }}>{ability}</Text>)}
        
        {inventory && <Text as="p" variant="body" sx={{ m: 0 }}>Inventory: </Text>}
        {inventory && inventory.map(item => <Text key={item} as="span" variant="label" sx={{ bg: "grayDark", color: "background", borderRadius: 2, px: "4px", mr: 2 }}>{item}</Text>)}
      </Box>
        
      <Box>
        {other}
      </Box>
    </Grid>
  )
}
