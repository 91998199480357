import React from "react"
import { Box, Text } from "theme-ui"

export default function Time({ description, time }) {
  return (
    <Box sx={{ display: "inline-flex", alignItems: "center", bg: "grayXLight", px: 1 }}>
      {description && <Text as="span" variant="label" sx={{ mb: 0 }}>{description}&nbsp;•&nbsp;</Text>}
      {/* <Box sx={{ width: 16, mr: 1 }}><TimeIcon /></Box> */}
      <Text as="span" variant="label" sx={{ m: 0 }}>{time}</Text>
    </Box>
  )
}
