import React from "react"
import { Grid, Box, Flex, Text } from "theme-ui"
import Time from "../Time"

export default function Part({ number, title, time, children }) {
  return (
    <Box sx={{ py: [7, 8] }} id={title.replace(/ /g,'-').toLowerCase()}>
      <Grid variant="base">
        <Box>
          <Flex sx={{ justifyContent: "flex-start", alignItems: "baseline", flexWrap: "wrap", mb: 2 }}>
            {number && <Text as="p" variant="label" sx={{ mb: 0, mr: 1 }}>Part {number}</Text>}
            <Time time={time} />
          </Flex>
          <Text as="h2" variant="title01" sx={{ fontFamily: "display" }}>{title}</Text>
        </Box>
      </Grid>
      
      {children}
    </Box>
  )
}
